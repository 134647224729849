import $api from "@/api";
import $store from "@/store";
import showNotice from '@/components/common/notice/notice.js'
import socketWorker from "@/utils/worker.js?worker"

function PostBlind_box_channel(id) {
    // console.log("join box channel webSocket_id;", id);
    $api.battle.joinChannel({ client_id: id }).then((res) => {
        // console.log("Join channel;", res);
    });
}
const fightStore = $store("arena");

// var md5 = require("blueimp-md5");

/****** WebSocket 工具类 ******/
/**
 * 为保证 this 指向
 * 内部方法声明使用 方法名(){} 的形式
 * 内部事件声明使用 方法名=()=>{} 的形式
 * 外部事件声明随意
 */
import md5 from 'blueimp-md5';
let count = 0
let timer = null
let cmd =[];
var MySocket = /** @class */ (function () {
    function MySocket() {
        // console.log('new socket');
        var _this = this;
        var offset = 0;
        var worker = new socketWorker();
        this.getOffset = function (e) {
            // console.log('offset;', offset);
            return offset;
        };
        /******* WebSocket 事件绑定 *******/
        //开启事件
        this.OnOpen = function (e) {
            // console.log('websocket OnOpen:',);
            // fightStore.reconnect()
            // _this.CloseInterval();
            // _this.OpenInterval();
            worker.postMessage('stop');
            var time = 0;
            var dur = 200;
            var heart = 3900;
            var infoHeart = 1000
            var infoTime = 0
            if(!worker){
                worker = new socketWorker();
            }
            worker.postMessage('start');
            worker.onmessage = e => {
                // console.log('e;',infoTime);
                
                infoTime += dur;
                if(infoTime > infoHeart){
                    //info心跳发送 每隔3秒发送一次info
                    if(_this.websock.readyState == 1){
                        _this.websock.send(JSON.stringify({"MsgType":"Controls","ApiCode":"info"}));
                    }
                    // console.log('send;',infoTime);
                    infoTime = 0;
                }
                if (e.data === "ended") {
                    // worker.terminate()
                }
                if (e.data === "auth") {
                    time += dur;
                    //处理事件
                    if (_this.isAuthen && _this.event.length > 0) {
                        console.log('event[0];', _this.event[0]);
                        _this.event[0](_this.id);
                        _this.event.splice(0, 1);
                    }
                    //发送心跳
                    if (time > heart) {
                        // console.warn('Send pong auth');
                        time = 0;
                        // _this.Send('z');
                        //25s  自动认证一次  时间自动推送  不用发送心跳验证链接
                        _this.Authen();
                        // console.log('$socket msg: send heart');
                    }
                    // console.log('web worker auth!'+time);
                }
                cmd.forEach(() => {
                    _this.websock.send(cmd.pop());
                });
            };
        };



        //失败事件
        this.OnError = function (e) {
            count++
            console.log('Websocket error:', e);
            // showNotice('f', { desc: 'websocket断开,请检查网络' })
            //断开重连,请求超时,优化
            console.log('Websocket reconnect...', count);
            if (count > 5) {
                // _this.CloseInterval();
                worker.postMessage('stop');
                _this.Close()
                console.log('Connect over 5 times,please try again later!');
                return
            }
            var options = {
                dir: "auto", // 文字方向
                body: "Connect failed,please refresh the website!", // 通知主体
                requireInteraction: true, // 不自动关闭通知
                // 通知图标
                icon:
                    "https://upload-images.jianshu.io/upload_images/5245297-818e624b75271127.jpg?imageMogr2/auto-orient/strip%7CimageView2/2/w/1240",
            };
            notifyMe("Socket will be reconnect", options);
            // let count = 0
            timer = setInterval(() => {
                if (count > 5) {
                    clearInterval(timer)
                    console.log('websocket too many connect');
                    notifyMe("Too many connect failed,Please try later!", options);
                    count = 0
                    // _this.Start();
                }
                // count++
            }, 2000);

        };

        /**
         * 全站
         * SitePublicData  站点公共数据
         * 
         * 轮播图
         * OpenBoxRecord 开箱推送开出道具记录
         * 
         * 对战相关事件
         * CreateGroup  监听创建对战
         * joinGroup    监听加入房间(加入对战)
         * OperateGroup 监听startnow 操作 (更新用户加入对战房间状态)
         *              startnow 通过websocket 发送数据给服务端,然后监听websocket   OperateGroup命令 更新用户状态
         * 
         *              websocket 发送创建命 websocket 发送加入房间命令令
         *
         * 操作对战房  退出  准备 站起 加入
         * 
         * 创建对战 调用api创建
         * 
         * 加入对战房间 ApiCode:arena_join
         * 操作对战房间 ApiCode:arena_operate a退出,b坐下(准备),c取消准备(站起),d与机器人一起
         * // 操作对战房发送websocket格式
        const sendData2 = {
            MsgType: "Controls",
            ApiCode:'arena_operate',//arena_join 加入对战房间  arena_operate(退出,坐下-准备-快速开始,取消准备,,与机器人一起,)
            Param: {
            game_arena_id: arenaDetailData.value.game_arena_id,
            status: status,//0取消准备  1立即开始  2允许对战加入机器人 3退出 
            user_id: u.userInfo.id,
            access_token: getToken(),
            },
        };

        //websocket 发送参数 转成json字符串
        const jsondata = JSON.stringify(sendData);
        //发送websocket消息
        proxy.$socket.Send(jsondata);
         * 
         */


        //接收事件
        this.OnMessage = function (e) {
            // console.warn('websocket OnMessage', e)
            // console.log('接收websocket OnMessage e:', e.data);
            var redata = JSON.parse(e.data);
            // console.log('websocket resp:', redata);
            if (redata.hasOwnProperty('code') && redata.code != 200) {
                // console.log("$socket err: code!=200");
                showNotice('f', { desc: redata.message })
            }
            if (redata.hasOwnProperty('MsgType') && redata.hasOwnProperty('Data')) {
                //分发
                // console.log("socket事件分发: MsgType【" + redata.MsgType + "】");
                _this.action[redata.MsgType] && _this.action[redata.MsgType](redata.Data);
                if (redata.MsgType == 'joinGroup') {
                    // console.log('zu;', redata.Data);
                }
                if (redata.MsgType == 'pong') {
                    const nowtime = Math.trunc(parseFloat((new Date).getTime() / 1000))
                    offset = ((nowtime - parseFloat(redata.Data.server_time)));
                    // offset = parseFloat(redata.Data.server_time)
                    console.warn('offsettime', offset);
                }
                if (redata.MsgType == 'ping') {
                    // console.log('websocket 响应 ping');

                    // var nowtime = (new Date).getTime();
                    // offset = parseInt((nowtime - redata.Data.server_time * 1000) / 1000);
                    const nowtime = Math.trunc(parseFloat((new Date).getTime() / 1000))
                    offset = ((nowtime - parseFloat(redata.Data.server_time)));
                    console.warn('offsettime', offset);
                }
                if (redata.MsgType == 'connect') {
                    // console.log('connect', redata.Data.client_id);
                    fightStore.reconnect(redata.Data.client_id)
                }
                // obj.name='''
                // obj.name(oo)
            }
        };
        //关闭事件
        this.OnClose = function (e) {
            // console.log('websocket closed', e);
            worker.postMessage('stop');
            // _this.CloseInterval();
            _this.Start();
            // console.log('$socket msg: close socket', e);
        };
        /****** 工具类辅助事件 ******/
        //认证
        this.Authen = function (res) {
            // console.log('this;',this);//此处this指向Object对象
            //_this指向MySocket对象
            _this.id = res?.client_id || sessionStorage.getItem("C_id");
            sessionStorage.setItem("C_id", _this.id);
            // console.log('频道id;', _this.id);
            var ran = Math.floor(Math.random() * Math.pow(10, 20)).toString();
            var data = {
                MsgType: 'auth',
                token: '',
                string: ran,
                time: new Date().getTime(),
                app_id: 'csgoskins'
            };
            var temp = _this.id + ran + data.time + data.app_id + 'h2T*V%&cQx6traMr';
            data.token = md5(temp);
            _this.isAuthen = true;
            _this.Send(JSON.stringify(data));
        };
        //开启定时器
        this.OpenInterval = function () {
            var time = 0;
            var dur = 200;
            var heart = 20000; 
            
            _this.timer = setInterval(function () {
                time += dur;
                //处理事件
                if (_this.isAuthen && _this.event.length > 0) {
                    console.log('event[0];', _this.event[0]);
                    _this.event[0](_this.id);
                    _this.event.splice(0, 1);
                }
                //发送心跳
                if (time > heart) {
                    console.warn('发送心跳');
                    time = 0;
                    // _this.Send('z');
                    //25s  自动认证一次  时间自动推送  不用发送心跳验证链接
                    _this.Authen();

                    // console.log('$socket msg: send heart');
                }

                console.log('心跳;',time);
                
            }, dur);
        };
        //关闭定时器
        this.CloseInterval = function () {
            _this.timer && clearInterval(_this.timer);
            _this.timer = null;
        };
        this.wsurl = import.meta.env.VITE_SOCKET_URL;
        this.action = {};
        this.event = [];
        // console.log('add websocket connect event')
        // this.Add('connect', this.Authen);
        this.Add('connect', function (res) {
            // console.log('this;',this);//此处this指向Object对象
            //_this指向MySocket对象
            _this.id = res?.client_id || sessionStorage.getItem("C_id");
            sessionStorage.setItem("C_id", _this.id);
            // console.log('频道id;', _this.id);
            PostBlind_box_channel(_this.id);
            var ran = Math.floor(Math.random() * Math.pow(10, 20)).toString();
            var data = {
                MsgType: 'auth',
                token: '',
                string: ran,
                time: new Date().getTime(),
                app_id: 'csgoskins'
            };
            var temp = _this.id + ran + data.time + data.app_id + 'h2T*V%&cQx6traMr';
            data.token = md5(temp);
            _this.isAuthen = true;
            _this.Send(JSON.stringify(data));
        });
    }
    //添加事件 自定义事件JoinGroup等事件
    MySocket.prototype.Add = function (name, func) {
        if (!this.action[name])
            this.action[name] = func;
        return this;
    };
    //添加事件  websocket 本身事件
    MySocket.prototype.Event = function (func) {
        this.event[this.event.length] = func;
        return this;
    };
    //移除事件
    MySocket.prototype.Remove = function (name) {
        if (this.action[name])
            delete (this.action[name]);
        return this;
    };
    //测试使用
    MySocket.prototype.Test = function () {
        for (var key in this.action) {
            if (Object.prototype.hasOwnProperty.call(this.action, key)) {
                if (key != 'connect')
                    this.action[key]();
            }
        }
    };
    //启动服务
    MySocket.prototype.Start = function () {
        // console.log('Websocket start');
        // this.websock && this.Close();
        this.websock = new WebSocket(this.wsurl);
        // console.log('new 了;', this.websock);
        this.websock.onopen = this.OnOpen;
        this.websock.onmessage = this.OnMessage;
        this.websock.onerror = this.OnError;
        this.websock.onclose = this.OnClose;
        this.isAuthen = false;
    };
    //发送数据
    MySocket.prototype.Send = function (data) {
        // console.log('websocket 发送数据',data);
        // console.log('this.websocket',this.websock);
        // console.log('websocket open',WebSocket.OPEN);
        if (this.websock.readyState == WebSocket.OPEN) {
            this.websock.send(data);
        }
        else{
            cmd.push(data);
        }
    };
    //关闭服务
    MySocket.prototype.Close = function () {
        console.log('Websocket closed', this);
        this.action = {};
        this.event = [];
        this.websock && this.websock.close();
    };
    return MySocket;
}());

function notifyMe(title, options) {
    console.log('Notification:', window.Notification);
    // 先检查浏览器是否支持
    if (!window.Notification) {
        console.log("Can't send notification");
    } else {
        // 检查用户曾经是否同意接受通知
        if (Notification.permission === "granted") {
            var notification = new Notification(title, options); // 显示通知
        } else if (Notification.permission === "default") {
            // 用户还未选择，可以询问用户是否同意发送通知
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    console.log("Customer granted!");
                    var notification = new Notification(title, options); // 显示通知
                } else if (permission === "default") {
                    console.log("Customer close grant,it could regrant before refesh the brower！");
                } else {
                    // denied
                    console.log("Customer refuse grant");
                }
            });
        } else {
            // denied 用户拒绝
            console.log("Customer refuse grant before");
        }
    }
}
function noticeForSystem() {
    console.log("System notification");
    // Notification.requestPermission().then((permission) => {
    //   // 根据用户的回答显示或隐藏按钮
    //   notificationBtn.style.display = permission === "granted" ? "none" : "block";
    // });
    var options = {
        dir: "auto", // 文字方向
        body: "Notification：Connect failed,please refresh the website!", // 通知主体
        requireInteraction: true, // 不自动关闭通知
        // 通知图标
        icon:
            "https://upload-images.jianshu.io/upload_images/5245297-818e624b75271127.jpg?imageMogr2/auto-orient/strip%7CimageView2/2/w/1240",
    };
    notifyMe("Notification title", options);
    // function notifyMe(title, options) {
    //     console.log('chrome通知测试', window.Notification);
    //     // 先检查浏览器是否支持
    //     if (!window.Notification) {
    //         console.log("浏览器不支持通知");
    //     } else {
    //         // 检查用户曾经是否同意接受通知
    //         if (Notification.permission === "granted") {
    //             var notification = new Notification(title, options); // 显示通知
    //         } else if (Notification.permission === "default") {
    //             // 用户还未选择，可以询问用户是否同意发送通知
    //             Notification.requestPermission().then((permission) => {
    //                 if (permission === "granted") {
    //                     console.log("用户同意授权");
    //                     var notification = new Notification(title, options); // 显示通知
    //                 } else if (permission === "default") {
    //                     console.warn("用户关闭授权 未刷新页面之前 可以再次请求授权");
    //                 } else {
    //                     // denied
    //                     console.log("用户拒绝授权 不能显示通知");
    //                 }
    //             });
    //         } else {
    //             // denied 用户拒绝
    //             console.log("用户曾经拒绝显示通知");
    //         }
    //     }
    // }
}

export default {
    install: app => {
        Object.defineProperty(app.config.globalProperties, '$socket', {
            value: new MySocket(),
            writable: false
        })
    }
}
// exports["default"] = {
//     install: function (Vue) {
//         Object.defineProperty(Vue.prototype, '$socket', {
//             value: new MySocket(),
//             writable: false
//         });
//     }
// }; // };
