<script setup>
import footerContainer from "@/components/common/footer/footerContainer.vue";
import navbar from "@/components/common/navbar/navbar.vue";
import autoAuth from "@/utils/autoAuth.js";
import swiper from "@/components/swiper/Swiper.vue";
import recharge from "@/components/common/recharge/index.vue";
// import {useStore} from '@/store/module/test.js';
import $store from "@/store";
import $api from "@/api";
import eventEmitter from "@/utils/emitter.js";
import { loginModal } from "@/components/common/login";
import rechargeModal from "@/components/common/recharge";
import playInfo from "@/components/common/playInfo/index.vue";
// import swiperg from "@/components/common/swiper/swiperg.vue";
import {
  toRefs,
  ref,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  computed,
} from "vue";
import { useRoute } from "vue-router";
import { getImageUrlByNewURL } from "@/utils/getImageUrl.js";

import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;
const route = useRoute();
const cacheComponents = computed(() => {
  const cacheRoutes = ["rolldetail", "daily"];
  return cacheRoutes.includes(route.name) ? ["GiftCase"] : [];
});

// import sp from '@/utils/sp.js'
// console.log('sp;',sp.value);
//站点公共信息
$api.user.getInfo().then((res) => {
  // console.log("站点公共信息", res);
  // $store("user").SitePublicDataFn(res);
});

//系统级别提示
function noticeForSystem() {
  console.log("系统级别提示");
  // Notification.requestPermission().then((permission) => {
  //   // 根据用户的回答显示或隐藏按钮
  //   notificationBtn.style.display = permission === "granted" ? "none" : "block";
  // });
  var options = {
    dir: "auto", // 文字方向
    body: "通知：Connect failed,please refresh the website!", // 通知主体
    requireInteraction: true, // 不自动关闭通知
    // 通知图标
    icon:
      "https://upload-images.jianshu.io/upload_images/5245297-818e624b75271127.jpg?imageMogr2/auto-orient/strip%7CimageView2/2/w/1240",
  };
  notifyMe("这是通知的标题", options);
  function notifyMe(title, options) {
    console.log("chrome通知测试", window.Notification);
    // 先检查浏览器是否支持
    if (!window.Notification) {
      console.log("浏览器不支持通知");
    } else {
      // 检查用户曾经是否同意接受通知
      if (Notification.permission === "granted") {
        var notification = new Notification(title, options); // 显示通知
      } else if (Notification.permission === "default") {
        // 用户还未选择，可以询问用户是否同意发送通知
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("用户同意授权");
            var notification = new Notification(title, options); // 显示通知
          } else if (permission === "default") {
            console.warn("用户关闭授权 未刷新页面之前 可以再次请求授权");
          } else {
            // denied
            console.log("用户拒绝授权 不能显示通知");
          }
        });
      } else {
        // denied 用户拒绝
        console.log("用户曾经拒绝显示通知");
      }
    }
  }
}

/**
 * 测试获取用户信息,未登录显示登录弹框
 */
function test() {
  $store("user")
    .getUserInfo()
    .then((res) => {
      console.log("res;", res);
    });
}
const { proxy } = getCurrentInstance();
const fightStore = $store("arena");
const siteStore = $store("siteInfo");
// console.log("站点公共信息", siteStore.isConnect);
// console.log("fightstore;", fightStore);

/**
 * 加入盒子竞技频道
 * @param {*} client_id websocket 频道id 全局加入一次 网站打开就加入
 */
function PostBlind_box_channel(id) {
  console.log("join box channel webSocket_id;", id);
  $api.battle.joinChannel({ client_id: id }).then((res) => {
    console.log("加入频道;", res);
  });
}

//加入房间
function JoinGroup(data) {
  console.warn("加入房间;", data);
  //把websocket推送的加入房间信息传给arenaStore,在对战详情页面通过监听arenaStore.AddInformation变化,做出加入对战的进一步逻辑处理
  fightStore.AddInformationFn(data);
}
//创建房间
function Accept(data) {
  // console.warn("全局App,vue监听到===>创建accept", data);
  fightStore.createArena(data);
}
//退出房间(取消准备,准备,允许机器人加入 退出房间)
function OperateGroup(data) {
  // console.warn("对战操作", data);
  fightStore.AddInformationFn(data);
}
//获取对战详情数据
function GetArenaData(data) {
  // console.warn("获取对战详情数据", data);
  fightStore.getArenaDetail(data);
}
//对战开始
function AddStartGroup(data) {
  // console.warn("对战开始", data);
  // fightStore.GetKaiShiVsData(data);
  fightStore.GetJieShuVsData(data);
}

//ping
function Ping(data) {
  // console.warn('socket ping;',data);
  fightStore.pingInfoMationFn(data);
}

//对战结束
function AddEndGroup(data) {
  // console.warn("对战结束;", data);
  // fightStore.GetJieShuVsData(data);
  fightStore.endGame(data);
}

//站点公共数据
function SitePublicData(data) {
  // console.warn("站点公共数据", data);
  siteStore.updateSiteInfo(data);
}

//获取首页箱子数据
function SitePublicBoxData(data) {
  // console.warn("获取首页箱子数据", data);
  siteStore.updateSiteBoxData(data);
}

//websocket 开箱推送的箱子 存放到轮播箱子列表
function OpenBoxRecord(data) {
  // console.warn("开箱推送的箱子 存放到轮播箱子列表", data);
  siteStore.updateHotcase(data);
}

//关闭websocket
const handleFn = () => {
  // console.log("切换浏览器标签页");
  // proxy.$socket.Close();
};
function reload() {
  // console.log("切换标签 执行 reload");
}
// proxy.$socket
//   .Event((e) => {
//     console.log("加入对战频道socket Event", e);
//     sessionStorage.setItem("C_id", e);
//     PostBlind_box_channel(e);
//   })
//   .Start();

proxy.$socket.Start();

/**
 * 发送z 获取服务器时间
 */
function getServerTime() {
  proxy.$socket.Send("z");
}

onMounted(() => {
  // console.log("切换显示当前 csgo标签页");
  // proxy.$socket.Start();
  // reload();
  // //添加监听 websocket实时监听对战加入事件  若有加入则执行加入房间函数JoinGroup
  proxy.$socket.Add("joinGroup", JoinGroup);
  proxy.$socket.Add("OperateGroup", OperateGroup);
  // //通过websocket实时监听对战结束事件  若对战结束则执行对战结束函数AddEndGroup
  proxy.$socket.Add("endGroup", AddEndGroup);
  proxy.$socket.Add("startGroup", AddStartGroup);
  proxy.$socket.Add("CreateGroup", Accept);

  //监听推送的对战详情数据
  proxy.$socket.Add("arena_detail", GetArenaData);

  //监听websocket 站点公共数据 SitePublicData
  proxy.$socket.Add("info", SitePublicData);

  //监听websocket推送的首页箱子数据
  proxy.$socket.Add("PublicAll", SitePublicBoxData);

  //监听开箱 推送的热门箱子 添加到顶部轮播箱子列表
  proxy.$socket.Add("OpenBoxRecord", OpenBoxRecord);

  proxy.$socket.Add("ping", Ping);

  document.addEventListener("visibilitychange", (e) => {
    //隐藏标签
    if (document.visibilityState === "hidden") {
      handleFn();
    }
    //切换显示标签
    if (document.visibilityState === "visible") {
      // console.log("切换显示当前 csgo标签页");
      // proxy.$socket.Start();
      reload();
      //添加监听 websocket实时监听对战加入事件  若有加入则执行加入房间函数JoinGroup
      proxy.$socket.Add("joinGroup", JoinGroup);
      proxy.$socket.Add("OperateGroup", OperateGroup);

      //通过websocket实时监听对战结束事件  若对战结束则执行对战结束函数AddEndGroup
      proxy.$socket.Add("endGroup", AddEndGroup);
      proxy.$socket.Add("startGroup", AddStartGroup);
    }
  });
  //浏览器关闭,浏览器标签页关闭,浏览器刷新均会触发 beforeunload 事件
  window.addEventListener("beforeunload", (e) => handleFn());
});
onUnmounted(() => {
  console.log("csgo页面销毁完毕");
  document.removeEventListener("visibilitychange", (e) => {
    if (document.visibilityState === "hidden") {
      console.log("移除 hidden");
      handleFn();
    }
  });
  window.removeEventListener("beforeunload", (e) => handleFn());
});

const userstore = $store("user");
// console.log('ustore;',userstore);
const teststore = $store("test");
// console.log('tstore;',teststore);
autoAuth();

// console.log('ts:',teststore.someState);
const { someState } = toRefs(teststore);
const { count } = toRefs(userstore);

//处理登录弹框
function showLogin() {
  console.log("showLogin");
  //弹登录框
  console.log("弹登录框");
  //未登录显示登录框
  loginModal(
    { visible: true, title: $t("loginLarge"), contentType: "def" },
    (callnack) => {
      // console.log('未登录 弹登录框');
      callnack();
    }
  );
}

//处理充值弹框
function showRecharge(data) {
  console.log("showRecharge",data);
  // return
  //如果未登录,则直接弹登录框,否则弹充值框
  if (!userstore.isToken) {
    showLogin();
  } else {
    //弹充值框
    console.log("弹充值框");
    rechargeModal({
      visible: true,
      // title: "hello",
      // options: { title: "hello", content: "world" },
    })
      .then((res) => {
        console.log("充值成功",res);
        if(data.pay == "fail"){
          resultBtnTxt.value = "TRY AGAIN";
          resultInfo.value = "payResultFailInfo";
          resultImg.value = getImageUrlByNewURL("systemicon/notice_error.png");
          resultVisible.value = true;
        }
      })
      .catch(() => {
        console.log("取消充值");
      });
  }
}

const playInfoVisible = ref(false);
const playInfoData = ref({});
function showPlayInfo(data) {
  console.log("showPlayInfo", data);
  playInfoVisible.value = true;
  playInfoData.value = data;
}

//充值结果弹框
const resultVisible = ref(false);
function closeResult() {
  // return;
  resultVisible.value = false;
}
const resultBtnTxt = ref("OK"); //TRY AGAIN
const resultInfo = ref("payResultSuccessInfo");
const resultImg = ref(getImageUrlByNewURL("systemicon/notice_success.png"));

onMounted(() => {
  //监听 订阅的登录弹框事件
  eventEmitter.on("api:un_auth", showLogin);
  eventEmitter.on("api:redirect", () => {
    // console.log("重定向");
    // return;
    router.push("/");
    location.reload()
  });

  //监听运作方式事件
  eventEmitter.on("api:play_info", (data) => {
    // console.log("运作方式弹框", data);
    // playInfo({ visible: true, data });
  });

  //监听 订阅的充值弹框事件
  eventEmitter.on("api:no_enough", showRecharge);
  eventEmitter.on("api:play_info", showPlayInfo);

  eventEmitter.on("api:pay_status", (data) => {
    const { pay } = data;
    console.log("app 充值status;", pay);
    if (pay == "success") {
      resultBtnTxt.value = "OK";
      resultInfo.value = "payResultSuccessInfo";
      resultImg.value = getImageUrlByNewURL("systemicon/notice_success.png");
      resultVisible.value = true;
    } else if (pay == "fail") {
      eventEmitter.emit("api:no_enough",{pay});
    }
  });

  //重置对战tabkey=1
  eventEmitter.on("reset:battleTabKey", () => {
    console.log("重置对战tabkey=1");
    userstore.battleKey = "1";
  });
  // console.log("eventEmitter:", eventEmitter);
});

//页面销毁,清除所有订阅的监听
onBeforeUnmount(() => {
  eventEmitter.offAllEvents();
});
</script>

<template>
  <!-- {{'sp:'+ sp }} -->
  <!-- <a-spin :spinning="sp" :tip="sp"> -->
  <!-- 轮播图 -->
  <!-- <swiperg></swiperg> -->
  <div class="head">
    <swiper v-if="10"></swiper>
    <!-- <di @click="noticeForSystem">系统提示</di> -->
  </div>
  <div class="type-area" :key="userstore.count">
    <navbar></navbar>
    <!-- 非首页,顶部需要有内边距 -->
    <router-view v-slot="{ Component }">
      <KeepAlive :include="cacheComponents">
        <component :is="Component" />
      </KeepAlive>
    </router-view>

    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
      <component :is="Component" />
      </keep-alive>
    </router-view> -->
  </div>
  <recharge></recharge>

  <!-- 运作方式弹框 -->
  <playInfo
    @close="playInfoVisible = false"
    :visible="playInfoVisible"
    :data="playInfoData"
  ></playInfo>

  <!-- 充值结果提示弹框 -->
  <myModal
    class="recharge-result-modal"
    :test="'test'"
    :visible="resultVisible"
    @close="closeResult($event)"
    :z-index="1001"
  >
    <template #cuscloseIcon>
      <img src="@/assets/images/systemicon/close.png" alt="" />
    </template>
    <template #body>
      <div class="tips">TIPS</div>
      <div class="content">
        <div class="info">
          <img :src="resultImg" alt="" />
          <div class="infos">
            {{ $t(resultInfo) }}
          </div>
        </div>
        <a-button class="result-btn" @click="closeResult($event)">{{
          resultBtnTxt
        }}</a-button>
      </div>
    </template>
  </myModal>

  <!-- 底部版权-联系我们区域 -->
  <footerContainer />
  <!-- </a-spin> -->
</template>

<style lang="scss" scoped>
//版心未设置
.test {
  font-size: 0.2rem;
  height: 800px;
}

//充值结果弹框
.recharge-result-modal {
  :deep(.ant-modal) {
    width: 4.96rem !important;
    height: 2.9rem;
    top: 20%;
    background-color: #0e121e;
    .ant-modal-close-x {
      position: absolute;
      top: 0.14rem;
      right: 0.1rem;
      img {
        width: 0.16rem;
      }
    }
    .ant-modal-body {
      height: 2.9rem;
      @extend %flex-all-center;
      flex-direction: column;
      .tips {
        color: #ffffff;
        font-size: 0.24rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      .content {
        width: 100%;
        @extend %flex-all-center;
        flex-direction: column;
        .info {
          @extend %flex-all-center;
          font-size: 0.17rem;
          font-weight: 400;
          color: #a3b2cf;
          .infos {
            width: max-content;
            max-width: 3.1rem;
          }
          img {
            width: 0.4rem;
            margin-right: 0.1rem;
          }
        }
        .result-btn {
          &:hover {
            background-color: #eb336b;
          }
          margin-top: 0.31rem;
          width: 1.7rem;
          height: 0.46rem;
          border-radius: 0.23rem;
          background-color: $active-color;
          font-size: 0.2rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
