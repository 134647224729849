/**
 * 事件中心  定义不同事件,用来处理不同事件的逻辑
 */

//事件名称 未登录,余额不足
const eventNames = ['api:un_auth', 'api:no_enough', 'lang:change','api:redirect','api:play_info','reset:battleTabKey','api:pay_status'];

//发布订阅模式
class EventEmitter {
  constructor() {
    this.listeners = {
      'api:un_auth': new Set(),
      'api:no_enough': new Set(),
      'lang:change': new Set(),
      'api:redirect': new Set(),
      'api:play_info': new Set(),
      'reset:battleTabKey': new Set(),
      'api:pay_status':new Set()
    };
  }

  on(eventName, listener) {
    // console.log('on event', eventName, listener);
    if (this.listeners[eventName]) {
      this.listeners[eventName].add(listener);
    }
  }

  emit(eventName, ...args) {
    // console.log('emit event', eventName, args);
    if (this.listeners[eventName]) {
      this.listeners[eventName].forEach((listener) => {
        listener(...args);
      });
    }
    // console.log('events list;', this.listeners);
  }
  // 取消特定事件的某个订阅者
  off(eventName, listener) {
    if (this.listeners[eventName]) {
      this.listeners[eventName].delete(listener);
    }
  }

  // 移除某个事件的所有订阅者
  removeAllListeners(eventName) {
    if (this.listeners[eventName]) {
      this.listeners[eventName].clear();
    }
  }
  // 移除所有事件的所有订阅者，遍历所有事件并清空对应的监听器集合
  offAllEvents() {
    for (const eventName of Object.keys(this.listeners)) {
      this.listeners[eventName].clear();
    }
  }
}

//导出单例
export default new EventEmitter();

/**
 * eventbus 用法
 * proxy.$EventBus.emit()
 * 
 * increment 增长
 * decrement 减少
 * noEnough 余额不足
 * eventbus
 * 
 * 在导航栏监听事件-upd 表示账户余额更新,增加或减少(扣费)
 * proxy.$EventBus.on()
 * 
 * 
 * 
 */
