import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";
import emitter from "@/utils/emitter";

//创建路由器  create a router
const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  //管理路由 manageRouting
  routes: [//一个个的路由规则
    {
      path: "/",
      redirect: '/box',//重定向到首页
    },
    {
      path:"/api/steam/handle",
      // beforeEnter: (to, from, next) => {
      //   console.log('beforeEnter',to)


      // }

    },
    {
      path: '/steamCallback',
      component: () => import('@/components/common/login/SteamCallback.vue')
    },
    {
      path: "/box",
      name: 'box',
      desc:'首页',
      component: () => import('@/page/box/index.vue')
    },
    {
      path: "/box/:id",
      name: "boxid",
      desc:'盲盒开箱详情页',
      component: () => import('@/page/boxdetail/index.vue')
    },
    {
      path: "/welfarebox/:id",
      name: "welfareboxid",
      desc:'福利箱开箱详情页',//任务+每日等级福利
      component: () => import('@/page/welfareboxdetail/index.vue')
    },
    {
      path: '/upgrade',
      name: 'upgrade',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/upgrade/index.vue')
    },
    {
      path: '/exchange',
      name: 'exchange',
      meta: {
        needLogin: true
      }
      , component: () => import('@/page/exchange/index.vue')
    },
    {
      path: '/battle',
      name: 'battle',
      component: () => import('@/page/battle/index.vue')
    },
    {
      path: '/create',
      name: 'create',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/createbattle/index.vue')
    },
    {
      path: '/broom/:id',
      name: 'battleRoom',
      component: () => import('@/page/battleroom//index.vue')
    },

    {
      path: '/roll',
      name: 'roll',
      component: () => import('@/page/roll/index.vue'),
    },
    {
      path: '/roll/:id',
      name: 'rolldetail',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/rolldetail/index.vue'),
    },
    {
      path: '/user',
      name: 'user',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/user/index.vue'),
      children:[{
        path: 'battle',
        component: () => import('@/page/battle/index.vue'),
      }
      ]
    },
    {
      path: '/seed/:rollId',
      name: 'seed',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/seedsetting/index.vue'),
    },
    {
      path: '/seed',
      name: 'seedNoRollId',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/seedsetting/index.vue'),
    },
    {
      path: '/mission',
      name: 'mission',
      desc:'任务墙',
      component: () => import('@/page/mission/index.vue'),
    },
    {
      path: '/daily',
      name:'daily',
      desc:'每日宝箱-等级福利箱',
      component: () => import('@/page/giftcase/index.vue'),
    },
    {
      path: '/slogin',
      name: 'slogin',
    },
    {
      path:'/policy/:id',
      // path:'/policy',
      name:'policy',
      component:()=>import('@/page/policy/index.vue')
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('@/page/test/index.vue'),
    }

  ],
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})


//监听是否未登录
// emitter.on('api:un_auth', (needLogin) => {

//   //未登录 弹登录框或跳到登录页
//   // router.push('/login')


//   // router.beforeEach((to, from, next) => {
//   //   if (to.matched.some(record => record.meta.needLogin)) {
//   //     if (needLogin) {
//   //       next()
//   //     } else {
//   //       next({
//   //         path: '/user',
//   //         query: { redirect: to.fullPath }
//   //       })
//   //     }
//   //   } else {
//   //     next()
//   //   }
//   // })
// })

export default router
