<!--Steam登录弹框-->
<template>
  <div class="steam">
    <myModal
      class="steam-login"
      :title="localTitle"
      :visible="visible"
      @close="close($event)"
    >
      <template #cuscloseIcon>
        <img src="@/assets/images/index/loginclose.png" alt="" />
      </template>
      <template #body>
        <div class="def-login" v-if="localContentType == 'def'">
          <div class="login-info">
            {{ $t("acceptInfo") }}
          </div>
          <div class="l-w agreement">
            <div class="d2">
              <a-checkbox v-model:checked="term1">
                <!-- {{ $t("isAgree",{servicePolicy: $t("servicePolicy")}) }} -->
                <i18n-t keypath="isAgree" :tag="false">
                  <template #servicePolicy>
                    <router-link :to="'/policy/' + 4">
                      <span @click="testServicePolicy2" class="policy">{{
                        $t("varServicePolicy")
                      }}</span>
                    </router-link>
                  </template>
                  <template #privacyPolicy>

                    <router-link :to="'/policy/' + 5">
                    <span class="policy">{{ $t("varPrivacyPolicy") }}</span>
                    </router-link>
                  </template>
                  <template #cardholderPolicy>
                    <router-link :to="'/policy/' + 7">
                    <span class="policy">{{ $t("varCardHolderPolicy") }}</span>
                    </router-link>
                  </template>
                </i18n-t>

                <!-- <span>{{ $t('serviceInfo')+',' }}</span>
                <span>{{ $t('privacyInfo')+',' }}</span>
                <span>{{ $t('privacyInfo')+',' }}</span> -->
              </a-checkbox>
              <a-checkbox v-model:checked="term2">
                {{ $t("already18") }}
              </a-checkbox>
            </div>
          </div>
          <div class="options">
            <a-button class="stean-btn cbtn-def" @click="doLogin('steam', $event)">
              <template #icon>
                <img
                  class="btn-icon"
                  src="@/assets/images/index/login_steam.png"
                  alt=""
                />
                <span class="btn-txt">{{ $t("loginLarge") }}</span>
              </template>
            </a-button>
            <a-button
              v-if="0"
              class="stean-btn cbtn-def cbtn-blue"
              @click="doLogin('email', $event)"
            >
              <template #icon>
                <img
                  class="btn-icon"
                  src="@/assets/images/index/login_email.png"
                  alt=""
                />
                <span class="btn-txt">{{ $t("emailLogin") }}</span>
              </template>
            </a-button>
          </div>

          <template v-if="isTest">
            <div class="devlogin">
              <div class="test" @click="testLogin">密码登录</div>
              <div class="test" @click="testLogin2">密码登录163</div>
            </div>
          </template>
        </div>
      </template>
    </myModal>
  </div>

  <div>
    <!-- Terms of Service  servicePolicy -->
    <!-- Privacy Policy  privacyPolicy -->
    <!-- Cardholder Agreement  cardholderPolicy -->

    <!-- I agree with the {servicePolicy}, {privacyPolicy}, and {cardholderPolicy} -->
    <!-- 本人同意{servicePolicy}、{privacyPolicy}及{cardholderPolicy}。 -->
  </div>
</template>

<script setup>
import { ref, reactive, toRaw, computed, onMounted } from "vue";
import showNotice from "@/components/common/notice/notice.js";
import $api from "@/api/index.js";
import $store from "@/store";
import { Form, message } from "ant-design-vue";
import handleResponse from "@/utils/handleResponseData.js";
import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;
import { useRoute, useRouter } from "vue-router";
const route = useRoute(); // 获取当前路由
const useForm = Form.useForm;
const router = useRouter();

function testServicePolicy() {
  console.log("to service policy");
  router.push("/policy/4");
}

const userStore = $store("user");
const isTest = import.meta.env.VITE_SHOW_TESTPAGE === "true";

const props = defineProps({
  visible: {
    type: Boolean,
    desc: "登录弹框开关",
    default: false,
  },
  title: {
    type: String,
    desc: "登录弹框标题",
    default: "steam 登录",
  },
  contentType: {
    type: String,
    desc: "登录弹框,可选值为 def  reg  reset  email",
    default: "",
  },
});

//登录弹框内容类型 def-默认  reg-注册/找回密码页 email-邮箱验证码/密码登录页
const localContentType = ref(props.contentType);
//弹框标题
const localTitle = ref(props.title);
const emits = defineEmits(["close", "login", "reg", "rest", "email"]);
const term1 = ref(false); //协议
const term2 = ref(false); //是否成年

//是否同意协议
function permit() {
  if (term1.value && term2.value) {
    return true;
  }
  showNotice("f", { desc: $t("requireAccept") });
  return false;
}

function toFAQ() {
  console.log("to FAQ");
}

function doLogin(type, e) {
  if (!permit()) {
    return;
  }
  // console.log("param:", type, e);
  e.stopPropagation();
  //通过steam登录
  if ("steam" == type) {
    // console.log("steam login");
    //获取当前页面路由
    // 创建 URL 对象
    const url = new URL(window.location.href);
    // 获取当前页面的路径
    const currentPath = url.pathname;
    console.log("currentUrl:", currentPath);
    localStorage.setItem("currentPath", currentPath);
    userStore.from = currentPath;
    steamLogin();
  }
}

//登录注册成功后更新站点数据
function LoginSuccessUpdate(res) {
  localStorage.setItem("token", res.access_token);
  showNotice("s", { desc: $t("loginSuccess") });
  //登录成功更新用户数据
  const token = localStorage.getItem("token");
  if (token) {
    userStore.$patch((state) => {
      state.isToken = true;
      if(state.count<=1){
        state.count++;
      }else{
        state.count = 1;
      }
      //tag  更新组件key  强制重新渲染组件 显示loading?
    });
    userStore.getUserInfo().then((res) => {
      console.log("登录成功,更新用户数据;", res);
      isClick.value = false;
    });
  }
  //更新数据后关闭登录弹框
  emits("close");
}

function parseUrl() {
  // 解析 URL
  const url =
    "https://api.skinroll.net/api/steam/handle?openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.mode=id_res&openid.op_endpoint=https%3A%2F%2Fsteamcommunity.com%2Fopenid%2Flogin&openid.claimed_id=https%3A%2F%2Fsteamcommunity.com%2Fopenid%2Fid%2F76561199650176724&openid.identity=https%3A%2F%2Fsteamcommunity.com%2Fopenid%2Fid%2F76561199650176724&openid.return_to=http%3A%2F%2Fapi.skinroll.net%2Fapi%2Fsteam%2Fhandle&openid.response_nonce=2024-12-17T07%3A24%3A59ZvV9iQpW9VO%2BboEvt2sKluic46zc%3D&openid.assoc_handle=1234567890&openid.signed=signed%2Cop_endpoint%2Cclaimed_id%2Cidentity%2Creturn_to%2Cresponse_nonce%2Cassoc_handle&openid.sig=cWayjBUfUBIlAm7H6Aw1JX39vTs%3D";
  const params = new URLSearchParams(url.split("?")[1]);

  // 获取各个参数值
  const openidNs = params.get("openid.ns");
  const openidMode = params.get("openid.mode");
  const openidOpEndpoint = params.get("openid.op_endpoint");
  const openidClaimedId = params.get("openid.claimed_id");
  const openidIdentity = params.get("openid.identity");
  const openidReturnTo = params.get("openid.return_to");
  const openidResponseNonce = params.get("openid.response_nonce");
  const openidAssocHandle = params.get("openid.assoc_handle");
  const openidSigned = params.get("openid.signed");
  const openidSig = params.get("openid.sig");

  console.log("openid.ns:", openidNs);
  console.log("openid.mode:", openidMode);
  console.log("openid.op_endpoint:", openidOpEndpoint);
  console.log("openid.claimed_id:", openidClaimedId);
  console.log("openid.identity:", openidIdentity);
  console.log("openid.return_to:", openidReturnTo);
  console.log("openid.response_nonce:", openidResponseNonce);
  console.log("openid.assoc_handle:", openidAssocHandle);
  console.log("openid.signed:", openidSigned);
  console.log("openid.sig:", openidSig);
}

// const steamLoginUrl = `https://steamcommunity.com/openid/login?openid.ns=http://specs.openid.net/auth/2.0&openid.mode=checkid_setup&openid.return_to=${encodeURIComponent(returnUrl)}&openid.realm=${encodeURIComponent(realm)}&openid.identity=http://specs.openid.net/auth/2.0/identifier_select`;
//steam登录 //bug 未实现
function steamLogin() {
  console.log("steam login");
  parseUrl();
  // return
  $api.user.loginSteam().then((res) => {
    console.log("steam res;", res);
    window.location.href = res.url;
  });
}

onMounted(() => {
  handleLoginResponse();
});

async function handleLoginResponse() {
  // 这里可以使用一个 AJAX 请求去获取 token 信息 //bug  怎么拿到token???
  const response = await fetch(window.location.href); ////bug 地址栏 这里不知道怎么写
  console.log("response;", response);
  if (response) {
    const data = await response.json();
    // 保存 token 和用户信息
    // localStorage.setItem("token", data);

    // 假设你有一个存储用户信息的变量
  } else {
    // 错误处理
  }
}

const isClick = ref(false);

function testLogin() {
  if (isClick.value) return;
  isClick.value = true;
  loginByPwdForEmail();
}
function testLogin2() {
  loginByPwdForEmail2();
}

//邮箱密码登录测试163
function loginByPwdForEmail2() {
  const admin = {
    email: "shoayu@163.com",
    // email: "18815526@gmail.com",

    password: "123456",
  };
  $api.user.emailLogin(admin).then((res) => {
    console.log("密码登录:", res);
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    LoginSuccessUpdate(resp);
  });
}

//邮箱密码登录
function loginByPwdForEmail() {
  const admin = {
    email: "18882358@gmail.com",
    password: "a123456",
  };
  $api.user.emailLogin(admin).then((res) => {
    console.log("密码登录:", res);
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    LoginSuccessUpdate(resp);
  });
}

//关闭登录弹框
function close(e) {
  e.stopPropagation();
  emits("close");
}
</script>
<style lang="scss" scoped>
//表单样式
.ant-form {
  //边框：2px 纯黄色；
  .ant-form-item {
    height: 88px;
    margin-bottom: 0px;

    .ant-input {
      height: 50px;
      border-radius: 25px;
      background-color: #050812;
      color: #8f9eb5;
      //边框：2px 纯绿色；
    }

    .ant-input::placeholder {
      //颜色：红色！重要；
      color: #363e53 !important;
    }
  }
}

.steam-login {
  :deep(.ant-modal) {
    width: 580px !important;
    height: 500px;
    border-radius: 10px;
    .ant-modal-content {
      height: inherit;
      background-color: #0e121e;
      background-image: url("@/assets/images/index/loginbgi.png");
      @extend %clearBgi;
    }
    .ant-modal-close {
      right: 30px !important;
      top: 30px !important;
    }

    .ant-modal-close-x {
      width: 20px !important;
      height: 20px !important;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .ant-modal-header {
      padding-top: 50px;
      .ant-modal-title {
        justify-content: center;
        font-size: 31px;
        font-weight: bold;
        color: #ffffff;
        font-family: 'Microsoft YaHei';
      }
    }
    .ant-modal-body {
      display: flex;
      justify-content: center;

      .login-info {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 42px;
        margin-top: 38px;
        text-align: center;
        line-height: 30px;
      }

      //note 抽离按钮通用样式  宽度 背景色 icon等
      .cbtn-def {
        width: 270px;
        height: 60px;
        border-radius: 30px;
        background-color: $active-color;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        position: relative;
        @extend %flex-all-center;

        .btn-icon {
          position: static;
          transform: none;
          width: 30px;
          height: 30px;
          margin-right: 20px;
        }
        .btn-txt {
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .cbtn-blue {
        background-color: #0057e6;
      }
      .grayBtnSend {
        background-color: #24293b;
        font-weight: bold;
        font-size: 20px;
        color: #575f72;
      }
      .verify-btn {
        z-index: 1;
        width: 90px;
        height: 50px;
        border-radius: 25px;
        font-size: 20px;
        font-weight: bold;
      }
      .grayBtnSend {
      }
      .ant-input-group {
        display: flex;
        position: relative;
        .verify-btn {
          position: absolute;
          right: 0;
        }
      }

      //默认
      .def-login {
        width: 464px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        .options {
          margin-top: 57px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 40px;
          .reg-info {
            cursor: pointer;
            text-align: center;
            .sp1 {
              color: #343c50;
            }
            .link {
              text-decoration: underline;
              color: $active-color;
            }
          }
        }
        //协议
        .d1,
        .d2,
        .ant-checkbox-wrapper {
          color: #91A2B7;
          font-weight: 400;
          font-size: 16px;
        }
        .d2 {
          .link {
            text-decoration: underline;
            font-size: bold;
          }
          .ant-checkbox-wrapper {
            line-height: 30px;
          }
          .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin: 0;
            margin-top: 6px;
          }
          a{
            font-weight: 400;
            color: #91A2B7;
            border-bottom: 2px solid #91A2B7;
            // text-decoration: underline;
            // // color: #9692b8;
            // color: #fff;
          }
          .policy {
            // color: #fff;
            font-weight: bold;
            // font-size: 20px;
            // text-decoration: underline;
          }
        }
        .l-w {
          position: relative;
        }
        .agreement {
        }
        .l-btn {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 110px);
          width: 180px;
          height: 50px;
          border-radius: 25px;
          background-color: $active-color;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .devlogin {
        position: absolute;
        top: 70%;
      }
    }
  }
}
</style>
