<template>
  <div class="back-bar">
    <div class="bar f-start" @click="back">
      <div class="back fwb fz16">
        <a-space>
          <left-outlined />
        </a-space>
        {{ $t("comeBack") }}
      </div>

    </div>
    <div class="f-center">
      <slot>
        <div class="box-name fwb" v-if="title">{{ title }}</div>
      </slot>
    </div>

    <slot name="info"></slot>
    <div class="bar f-end" v-if="fair">
      <slot name="fair">
        <div class="ver" @click="checkRoll">
          <img src="@/assets/images/systemicon/verify.png" alt="" />
          <div class="fwb">{{ $t("fairnessVerificationData") }}</div>
        </div>
      </slot>
    </div>
    <div v-else class="bar f-end"></div>
  </div>
</template>
<script setup>
import { LeftOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
const router = useRouter();
function back() {
  router.back();
}
function checkRoll(roll_id) {
  router.push(`/seed`);
}
const props = defineProps(["title", "fair"]);
</script>
<style lang="scss" scoped>
//非首页 显示返回首页按钮
.back-bar {
  display: flex;
  // width: 100%;
  // position: absolute;
  margin: 0 1rem;
  padding-bottom: 0.54rem;
  justify-content: space-between;
  align-items: center;
  .bar {
    display: flex;
    // width: 3rem;
  }
  .f-start {
    justify-content: flex-start;
    cursor: pointer;
  }
  .f-end {
    min-width: 1.6rem;
    width: max-content;
    justify-content: flex-end;
  }
  .f-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .back {
    // border: 2px solid red;
    width: 1.6rem;
    height: 0.44rem;
    background-color: #1b2132;
    color: #ffffff;
    border-radius: 0.05rem;
    @extend %flex-all-center;
    &:hover {
      background-color: #20273b;
    }
  }
  .box-name {
    font-size: 0.48rem;
  }
  .ver {
    cursor: pointer;
    width: 2.6rem;
    height: 0.44rem;
    background-color: #16311d;
    color: $fair-color;
    border-radius: 22px;
    font-size: 0.16rem;
    font-weight: 400;
    @extend %flex-all-center;
    &:hover {
      background-color: rgba(29, 65, 39, 0.8);
    }
    img {
      width: 0.18rem;
      height: 0.22rem;
      margin-right: 0.1rem;
    }
  }
}
</style>
