import { getAction, postAction, menuGetAction } from "@/request/menage.js";
import { getPayList } from "../request/api";
import { bind } from "lodash";
// import { getBagInfo } from "../request/api";
export default {

  //邮箱注册 邮箱密码登录  邮箱验证码登录 发送邮箱验证码  邮箱修改密码

  /**
   * 邮箱注册
   * @param {*} email 邮箱 
   * @param {*} password 密码 
   * @param {*} verify 验证吗 
   * @param {*} invite 邀请吗 
   * @returns access_token  返回token
   */
  emailReg: (params) => postAction('/email_register', params),

  /**
   * steamCallback 回调验证
   * @param {*}  params 地址栏问号后面所有数据
   * @returns 
   */
  handleSteam: (params) => getAction('/steam/handle', params),

  /**
   * 邮箱密码登录
   * @param {*} email 邮箱 
   * @param {*} password 密码 
   * @returns 
   */
  emailLogin: (params) => postAction('/email_login', params),


  /**
   * 邮箱验证码登录
   * @param {*} email 邮箱 
   * @param {*} verify 验证码 
   * @returns 
   */
  vemail_login: (params) => postAction('/vemail_login', params),

  /**
   * 发送邮箱验证码
   * @param {*} email 邮箱 
   * @param {*} is_use 用途(,1:注册/绑定邮箱 2:登录 3:修改密码) 
   * @returns 
   */
  sendEmailVerify: (params) => postAction('/send_email', params),

  /**
   * 绑定邮箱
   * @param {*} email 邮箱 
   * @param {*} verify 验证码 
   * @returns 
   */
  bindEmail: (params) => postAction('/bind_email', params),

  /**
   * 邮箱修改密码
   * @param {*} email 邮箱 
   * @param {*} password 密码 
   * @param {*} verify 验证码 
   * @returns 
   */
  resetEmialPassword: (params) => postAction('/ereset_password', params),


  //stean登录方法
  loginSteam() { return getAction('/steam/login') },

  /**
   * @description 注册账号
   * @param {*} mobile 手机号 
   * @param {*} password 密码 
   * @param {*} verify 验证码 
   * @param {*} invite 邀请码 
   * @returns {object} access_token and expires_in  token和有效期
   */
  registerAccount(params) { return postAction("/register", params) },

  /**
   * @description 密码登录
   * @param {*} mobile 手机号
   * @param {*} password 密码
   * @returns 
   */
  login(params) { return postAction('/login', params) },

  /**
   * @description 短信登录
   * @param {*} mobile 手机号 
   * @param {*} verify 验证码 
   * @returns 
   */
  smsLogin: (params) => postAction("/sms_login", params),

  /**
   * 修改密码
   * @param {*} mobile 手机号 
   * @param {*} password 密码 
   * @param {*} verify 验证码 
   * @returns 
   */
  reset_password: (params) => postAction('/reset_password', params),

  /**
   * @description 发送验证码
   * @param {*} mobile 手机号 
   * @param {*} is_use 验证码用途 (1:注册;2:登录;3:修改密码)
   * @returns 
   */
  regSendSms: (params) => postAction("/send_sms", params),

  /**
   * 个人信息
   * @returns 用户信息
   */
  getPerInfo: () => getAction("/me"),

  //修改推广码

  //绑定推广人邀请码

  //修改头像

  //修改用户名


  /**
   * 修改stream交易链接
   * @param {*} stream_url steam交易链接
   * @returns 
   */
  editeStreamLink: (params) => postAction("/set_steam_url", params),

  //背包
  /**
   * 背包道具(皮肤)
   * @param {*} page 页码 
   * @param {*} status 状态 不传:全部 ; 传参数 : 指定参数 状态 0:背包中，1：提货成功，2：回收，3：冻结中，4：申请提货，5：正在提货，6：等待发货，7：合成分解 8置换 9拆解
   * status=0和9 表示可使用,其他表示不可使用 9不可提货,0可以提货
   * @returns {object} type "type": 1, 记录类型（1：开箱，2：福利箱，3:对战，4：幸运开箱，5：饰品商城,6:幸运夺宝，7:合成/合约，8:roll,9:血战,10:后台赠送,置换：11） 类型为10的不可回收
   */
  getBagInfo: (params) => getAction("/storage", params),

  /**
   * 用户背包记录(用户未登录查看背包记录,用户登录时调用/storage接口) 
   * @param {*} user_id 用户id
   * @param {*} page 页码 
   * @returns 
   */
  getUserBagRecord: (params) => getAction("/userData/storage", params),




  /**
   * 道具分解/卖出/回收
   * @param {*}  data，传json{id:奖励id，uuid:设备码}，多选或单选，uuid可传可不传，id=all 为一键回收
   * @returns 
   */
  handleCash: (params) => postAction('/cash', params),

  /**
   * 饰品道具 提货(提取奖品)
   * @param {*} data  传json{id:奖励id}，多选或单选
   * @returns 
   */
  handleExtract: (params) => postAction('/extract', params),


  //  支付列表 金币列表 金币充值 卡密充值
  /**
   * 充值支付列表
   * @param {*}  client_type 客户端类型（Pc,H5,App），注意字母大小写
   * @returns 
   */
  getPaymentList: (params) => getAction('/payList', params),

  /**
   * 金币列表
   * @param {*}  channel 充值通道
   * @returns 
   */
  getBeansList: (params) => getAction('/beans', params),

  /**
   * 金币充值api
   * @param {*}  id, 充值额度id
   * 
   * @param {*}  currency: 虚拟货币参数
   * 
   * @param {*}  coupon_code, 为空则不使用优惠券，使用则传入优惠券code码
   * @param {*}  pay_way, 充值类型(1:支付宝官方PC，2:微信，3:支付宝H5，4:支付宝APP)
   * @returns 
   */
  handleRecharge: (params) => postAction('/recharge', params),

  /**
   * 金币充值订单详情
   * @param {*} code 订单号 
   * @returns 
   */
  beanDetail: (params) => getAction('/bean/detail',params),

  /**
   * 提货记录
   * @param {*} page 页码  
   * @returns 
   */
  extractRecord: (params) => getAction('/extract_record', params),

  /**
   * 金币充值记录
   * @param {*} page      页码 
   * @param {*} sta_time  开始时间 
   * @param {*} end_time  结束时间 
   * @returns 
   */
  rechargeRecord: (params) => getAction('/recharge_record', params),



  /**
   * 获取虚拟货币汇率
   * @param {*} amount 金额 
   * @param {*} currency_from 货币 
   * @param {*} currency_to 转换货币
   * @returns 
   */
  getEstimate: (params) => postAction('/estimate', params),

  /**
   * 卡密充值api
   * @param {*} card 卡密
   * @returns 
   */
  handleKaMiRecharge: (params) => postAction('/card-recharge', params),


  //优惠券

  //优惠券详情

  //下级列表

  //合作推广

  //实名认证

  //实名认证人脸





  /**
   * @description 站点信息
   * @param {*} params 
   * @returns 
   */
  getInfo: (params) => getAction("/info", params),

  /**
   * 未登录 用户数据
   * @param {*} user_id 用户id
   * @returns 
   */
  getUserData: (params) => getAction("/userData", params),

  /**
   * 用户最佳掉落
   * @param {*} user_id 用户id 
   * @returns 
   */
  getUserBestDropAward: (params) => getAction('/win_drop', params),

  /**
   * 用户对战记录
   * @param {*} user_id 用户id 
   * @param {*} page 页码 
   * @returns 
   */
  getBattlesLog: (params) => getAction('/battlesLog', params),

  /**
   * 用户对战场次
   * @param {*}  user_id 用户id 
   * @param {*}  type 类型 2,3,4 vs:2v2 不传type 为全部类型
   * @returns  is_win 是否胜利 1:胜利显示W 0失败显示L
   */
  getUserBattles: (params) => getAction('/battles', params),

  /**
   * 用户升级--只返回用户升级记录
   * @param {*} user_id 用户id  
   * @param {*} page 页码 
   * @param {*} page_size 每页条数 默认返回9条
   * @returns 
   */
  getUpgradesLog: (params) => getAction('/upgradesLog', params),

  /**
   * 用户roll 个人记录
   * @param {*} user_id 用户id 
   * @param {*} page 页码 
   * @param {*} page_size 每页条数 默认返回9条
   * @returns 
   */
  getRoomLog: (params) => getAction('/roomLog', params),

  /**
   * 查看种子
   * @returns 
   */
  checkSeed: () => postAction('/seed'),

  /**
   * 修改服务器种子
   * @param {*}  
   * @returns 
   */
  editServerSeed: () => postAction('/editServerSeed'),

  /**
   * 修改客户端种子
   * @param {*} client_seed 客户端种子
   * @returns 
   */
  editClientSeed: (params) => postAction('/editClientSeed', params),

  /**
   * 用户种子记录
   * @returns 
   */
  clientSeedLog: () => postAction('/clientSeedLog'),

  /**
   * 服务器种子记录
   * @returns 
   */
  serverSeedLog: () => postAction('/serverSeedLog'),

  /**
   * 掷骰记录
   * @param {*} RollId 掷骰id 
   * @returns 
   */
  diceLog: (params) => getAction('/dice', params),


}