<!-- 运作方式弹框 -->
<template>
  <myModal class="playInfo-modal" @close="close" :visible="visible">
    <template #body> 
      <div class="title">{{ title }}</div>
      <div class="content">
        <!-- <ol>
          <li v-for="(citem,index) in infoList" :key="index"></li>
        </ol> -->
          <div class="col" v-for="(citem,index) in infoList" :key="index">{{ citem }}</div>
          
      </div>
       </template>
  </myModal>
</template>

<script setup>
import { ref,computed } from 'vue'
const props = defineProps(['visible', 'data'])
const infoList = computed(()=>props.data.infos)
const title = computed(()=>props.data.title)

const newClientSeed = ref('')
const emits = defineEmits(['close', 'update'])
function close() {
  console.log('close');
  emits('close')
}
</script>
<style lang="scss" scoped>
// 运作方式弹框
.playInfo-modal {
  :deep(.ant-modal) {
    width: 8.8rem !important;
    position: relative;
    

    .ant-modal-body {
      min-height: 4.6rem;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: .53rem .51rem .15rem.51rem;
      font-size: .2rem;
      
      .title{
        font-weight: bold;
        color:#ffffff;
        margin-bottom: .4rem;
      }
      .content{
        // min-height: 4rem;
        // height: max-content;
        // overflow-y: scroll;
        // border: 1px solid #9CABC7;
        font-size: 400;
        color: #9CABC7;
        line-height: .26rem;
        .col{
          margin-bottom: .2rem;
        }
      }

      
    }

    .ant-modal-footer {
      .ant-btn {
        background-color: $active-color;
        width: 1.8rem;
        height: 0.5rem;
        border-radius: 0.5rem;
        font-size: 0.2rem;
        font-weight: bold;
        color: #ffffff;
        transform: translateY(-0.41rem);
      }
    }
  }
}


</style>
