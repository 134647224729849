import { getImageUrlByNewURL } from "@/utils/getImageUrl.js";
import handleResponse from "@/utils/handleResponseData.js";
import showNotice from '@/components/common/notice/notice.js'
import { computed, ref } from "vue";
import Title from "ant-design-vue/lib/typography/Title";
import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;

//经验值bar宽度
// ((userInfo.xp || publicUser.xp) /
//                         (userInfo.next_level_xp || publicUser.next_level_xp)) *
//                         100 +
//                       '%'
export const expBarWidth = computed(() => {
  //bug 切换对战场次 经验值进度条会执行
  return (xp, next_level_xp,last_level_xp) => {
    // return `${(exp / 100) * 100}%`
    // console.log('xp;', xp, 'next;', next_level_xp);

    const differenceLeft =  xp-last_level_xp
    const differenceRight = next_level_xp - last_level_xp

    const w = parseFloat((differenceLeft / differenceRight) * 100).toFixed(0) + '%'
    console.log('w;', w);
    return w
  }
})

export const battlePattern = ref([
  {
    id: 1,
    type: 2,
    desc: "两人房",
    active: true,
  },
  {
    id: 2,
    type: 3,
    desc: "三人房",
  },
  {
    id: 3,
    type: 4,
    desc: "四人房",
  },

  {
    id: 4,
    type: 'vs',
    desc: "2vs2",
  },
  {
    id: 5,
    type: 'all',
    desc: "全部",
  }
])
//根据最后五次对战记录-is_win字段 输出结果 is_win=1 胜利-返回W 0 失败-返回L
export const latelyInfo = computed(() => {
  return (item) => {
    return item.is_win == 1 ? ['W', 'w-color'] : ['L', 'l-color']
  }
})
/**
 * 切换对应类型的对战模式
 * @param {*} item battlePattern 每项
 * @param {*} $api $api对象
 * @param {*} storageList 个人中心storageList对象
 */
export function getBattles(item = battlePattern.value[0], $api, storageList, userInfo) {
  console.log('对战场次');
  console.log('item.type;', item.type);
  console.log('userid;', userInfo);
  console.log('$api;', $api);
  battlePattern.value.forEach((item) => { item.active = false });
  item.active = true;
  //动态获取用户id
  let type = undefined
  if (item.type != 'all') type = item.type

  console.log('before request');
  console.log('userinfo;', userInfo);
  const tempId = userInfo.value?.id || userInfo.id
  console.log('tempId;', tempId);
  $api.user.getUserBattles({ params: { user_id: tempId, type: type } }).then((res) => {
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    console.log("用户对战场次:", resp);
    storageList.battles = resp;
  });
  console.log('end');
}

//道具处理方式
export const awardControl = [
  {
    id: 1,
    title: 'receiveIcon',
    desc: "提货",
    type: "extract",
    class: "receive",
    iconpath: getImageUrlByNewURL("user/receive.png"),
  },
  {
    id: 2,
    title: 'sellIcon',
    desc: "回收",
    type: "cash",
    class: "sell",
    iconpath: getImageUrlByNewURL("user/sell.png"),
  },
  {
    id: 3,
    title: 'upgradeIcon',
    desc: "升级",
    type: "jumpUpgrade",
    class: "upgrade",
    iconpath: getImageUrlByNewURL("user/upgrade.png"),
  },
  {
    id: 4,
    title: 'exchangeIcon',
    desc: "置换",
    type: "jumpExchange",
    class: "exchange",
    iconpath: getImageUrlByNewURL("user/exchange.png"),
  },
];

export const awardStatus = computed(() => {
  return (status) => {
    return awardStatusMap[status].iconpath
  }
})
export const awardIconClass = computed(() => {
  return (status) => {
    // console.log('status;',status);
    awardStatusMap[status].class
    // console.log('class;',awardStatusMap[status].class);
    return awardStatusMap[status].class
  }
})
const awardStatusMap = {
  1: {
    desc: '提货成功',
    iconpath: getImageUrlByNewURL('user/success.png'),
    class: 'success-bg'
  },
  2: {
    desc: '回收',
    iconpath: getImageUrlByNewURL('user/selled.png')
  },
  3: {
    desc: '冻结中',
    iconpath: getImageUrlByNewURL('user/success.png')
  },
  4: {
    desc: '申请提货',
    iconpath: getImageUrlByNewURL('user/wait.png')
    , class: 'wait-bg'
  },
  5: {
    desc: '正在提货',
    iconpath: getImageUrlByNewURL('user/wait.png')
    , class: 'wait-bg'
  },
  6: {
    desc: '等待发货',
    iconpath: getImageUrlByNewURL('user/wait.png')
    , class: 'wait-bg'
  },
  7: {
    desc: '合成分解',
    iconpath: getImageUrlByNewURL('user/upgraded.png')
  },
  8: {
    desc: '置换',
    iconpath: getImageUrlByNewURL('user/exchanged.png'),
    class: 'exchange-bg'
  },

}

const awardTypeMap = {
  11: {
    desc: '道具来源于置换',
    title: 'EXCHANGE',
    url: '/exchange',
    iconpath: getImageUrlByNewURL('user/fromExchange.png'),
  },
  8: {
    desc: '道具来源于roll房',
    title: 'ROLL',
    url: '/roll',
    iconpath: getImageUrlByNewURL('user/fromRoll.png'),
  },
  7: {
    desc: '道具来源于合约',
    title: 'UPGRADED',
    url: '/upgrade',
    iconpath: getImageUrlByNewURL('user/fromUpgrade.png'),
  },
}
export const awardTypeIcon = computed(() => {
  return (type) => {
    // console.log('type;',type);
    // awardTypeMap[type].class
    // console.log('class;',awardTypeMap[type].class);
    // return awardTypeMap[type].iconpath

    return {
      title: awardTypeMap[type].title,
      iconpath: awardTypeMap[type].iconpath,
      url: awardTypeMap[type].url
    }
  }
})



/**
 * 道具操作
 * @param {object||array} aitem  背包饰品道具对象
 * @param {object} acitem awardControl对象的每一项
 * @param {object} router 路由对象
 * @param {object} api 接口对象
 * @param {object} proxy vue proxy对象
 * @param {object} u userstore对象
 */
export function handleAwardItem(aitem, acitem, router, $api, proxy, u) {
  // console.log("aitem:", aitem,router);
  console.log('handleAwardItem');
  switch (acitem.type) {
    case "extract":
      toTake(aitem, $api);
      break;
    case "cash":
      toRecycle(aitem, $api, proxy, u);
      break;
    case "jumpUpgrade":
      toUpgrade(aitem, router);
      break;
    case "jumpExchange":
      toExchange(aitem, router);
      break;
    default:
      console.log("未知状态");
  }

  // 0:背包中，1：提货成功，2：回收，3：冻结中，4：申请提货，5：正在提货，6：等待发货，7：合成分解 8置换 9拆解
}

//回收
export function toRecycle(award, $api, proxy, u) {
  console.log("回收", award);
  // const data = JSON.stringify({id:award.box_award_id})//传递的是json字符串  如何传递json对象
  const data = [{ id: award.id }]
  $api.user.handleCash({ data: data }).then(res => {
    console.log("回收成功", res);
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    //通知导航栏消费/更新余额 开箱总价 increment 增加 decrement 减少
    const updVal = parseFloat(award.bean);
    proxy.$EventBus.emit("upd", { type: "increment", val: updVal, paytype: 'recycle' });
    proxy.$EventBus.emit("updSell", { type: "decrement", val: parseFloat(award.bean) });
    localStorage.setItem("cashedAll", true);
    showNotice('s', { desc: resp })
    award.status = 2;
    console.log("回收", award);
  })
}

//提货
export function toTake(award, $api) {
  console.log("提货", award);
  // const data = JSON.stringify({id:award.id})
  const data = [{ id: award.id }]
  $api.user.handleExtract({ data: data }).then(res => {
    console.log("提货成功", res);
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    showNotice('s', { desc: resp })
    award.status = 4;//申请提货
  })
}

function handleUpgradeAndExchange(type, award) {
  // typeof award === 'array'   返回object
  let list = []
  if (Array.isArray(award))
    list = award
  else
    list.push(award)

  // const arra = new Array(85).fill(0).map((item, index) => index + 1);
  // let list = arra

  if (type === 'upgrade') {
    console.log("升级", list);
    return list
  } else if (type === 'exchange') {
    console.log("置换", list);
    return list.slice(0, 75)
  }
}
//升级
export function toUpgrade(award, router) {
  console.log("升级", award);
  //跳到升级页面,把当前道具选中

  const list = handleUpgradeAndExchange('upgrade', award)
  console.log('list;', list);
  router.push({ name: "upgrade", state: { data: JSON.stringify(list) } });
}


//置换
export function toExchange(award, router) {
  console.log("置换", award);

  // const data = JSON.stringify({id:award.id,ids:arra})
  //跳到置换页面,把当前道具选中


  //置换最多选择前75个道具
  const list = handleUpgradeAndExchange('exchange', award)
  console.log('list;', list);
  router.push({ name: "exchange", state: { data: JSON.stringify(list) } });
}




//前往stream 连接
export function toSteam(prefix_url = 'https://steamcommunity.com/profiles', stream_id) {
  console.log("前往stream 连接");
  window.open(`${prefix_url}/${stream_id}`);
}

//0 和 9  可回收
//0  可提货
//box_award_id  饰品id  不是背包id


//把0 和 9 排在前面 其余数字顺序不变
const arr = [3, 5, 0, 7, 9, 1, 2, 4, 6, 8];
// console.log("before arr;", arr);
arr.sort((a, b) => {
  if (a === 0 || a === 9) {
    return -1; // 让0和9排在前面
  }
  if (b === 0 || b === 9) {
    return 1; // 让0和9排在前面
  }
  return 0; // 其他数字不变顺序
});
// console.log("after arr;", arr);