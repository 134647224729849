import { computed } from "vue";


//根据用户等级返回对应的等级色
export const useDynamicLevelColor = computed(() => {
  return (level=1) => {
    //用户等级颜色
    //1-20 lv1 21-40 lv2 41-60 lv3 61-80 lv4 81-100 lv5 101-120 lv6
    if (level < 21) {
      return "#b0c3d9";
    } else if (level < 41) {
      return "#4b69ff";
    } else if (level < 61) {
      return "#8847ff";
    } else if (level < 81) {
      return "#d32ce6";
    } else if (level < 101) {
      return "#eb4b4b";
    } else {
      return "#e4ae39";
    }
  };
})

  