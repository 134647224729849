import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import myModal from '@/components/common/modal/index.vue'
// import steamLogin from './steamLogin.vue'
import index from './index.vue';
import scroll from '@/utils/noscroll.js';

import { render, h, createApp, ref } from 'vue';
import { divide } from 'lodash';

export default function rechargeModal(params, callback) {
  console.log('params;', params);

  return new Promise((resolve, reject) => {
    //保存组件实例
    const rechargeRef = ref(null);

    //创建容器
    const mountNode = document.createElement('div');
    //将容器插入到body内
    document.body.appendChild(mountNode);

    //创建Vue实例/节点
    const app = createApp({
      render() {
        // 这里使用了h()函数,等价于<myForm :title="title" :text="text" :options="options" @submit="..." @onClosed="..."></myForm

        //index组件 表示充值弹框组件对象
        return h(index, {
          ref: rechargeRef,
          //参数
          visible: params.visible,
          // title: params.title,
          // //配置项目
          // options: params.options,

          //事件回调
          onClosed: () => {
            //这里将容器给清除掉
            document.body.removeChild(mountNode);
            console.log('关闭充值弹框');
            //执行回调函数
            reject()
            //销毁该组件实例
            app.unmount();
            mountNode.remove();
          },
          'onApi:pay': (data) => {
            console.log('支付回调', data);
            //执行回调函数
            resolve(data)
            //关闭弹框
          }

          // props: {
          //   params: params,
          //   callback: callback
          // }
        })
      }
    })
    app.provide('scroll', scroll)
    app.component('myModal', myModal)
    app.use(Antd)
    app.mount(mountNode)
    resolve({tips:'显示充值弹框'})
  })
}